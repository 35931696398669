.App {
  /* text-align: center; */
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.svd_commercial_text {
  visibility: hidden;
  padding: 0;
}

#surveyCreatorContainer {
  direction: ltr;
  padding-left: 50px;
  padding-right: 50px;
}

.fixModal {
  top: auto !important;
  left: auto !important;
  width: 50% !important;
  height: auto !important;
  overflow: visible !important;
}

.sv_complete_btn {
  visibility: hidden;
}

.sv_header {
  text-align: 'right'
}

.ant-select-selection__placeholder {
  text-align: right !important;
  font-family: Tajawal !important;
  padding-top: 3px;
}

.ant-select-selection-selected-value {
  text-align: right !important;
  font-family: Tajawal !important;
  padding-top: 3px;
}

.ant-input-number-handler-wrap {
  position: absolute;
  left: 0;
}