
.sv_main span {
    margin-right: 5px !important;
    font-family: Tajawal;
    font-weight: bold;
    font-size: 17px;
}

.sv_page_title {
    visibility: hidden;
    height: 0px;
}

.ant-divider-horizontal {
    margin: 4px 0 !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup.sv_q_radiogroup_inline:not(:last-child), .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_checkbox.sv_q_checkbox_inline:not(:last-child), .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_imgsel.sv_q_imagepicker_inline:not(:last-child) {
    margin-right: 0 !important;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    font-family: Tajawal !important;
    padding-top: 5px !important;
}

.ant-card-head-title {
    font-family: Tajawal !important;
    padding-top: 15px !important;
}

.ant-descriptions-item-label {
    font-family: Tajawal !important;
    padding-top: 20px !important;
    font-size: 15px !important;
}

.ant-descriptions-item-content {
    font-family: Tajawal !important;
    padding-top: 20px !important;
    font-size: 15px !important;
}

.ant-modal-title {
    font-family: Tajawal !important;
    padding-top: 5px !important;
    font-size: 20px !important;
    text-align: right;
}

.ant-modal-close {
    left: 0 !important;
    right: unset !important;
}

.ant-form-item label {
    font-family: Tajawal !important;
    padding-top: 20px !important;
    font-size: 15px !important;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    text-align: right !important;
}

.ant-modal-footer button + button {
    margin-right: 8px !important;
}