.logo {
	height: 32px;
	margin: 16px;
	background-image: url(../assets/joussour.png)
}

.ant-menu.ant-menu-dark {
	text-align: right !important;
	font-family: Tajawal !important;
	padding-top: 50px !important;
}

.anticon {
	vertical-align: 0.15em !important;
}

.ant-input {
	padding-bottom: 0px !important;
}

.limelight {
	direction: rtl;
	text-align: right;
}

.ant-breadcrumb-link {
	font-family: Tajawal;
	font-weight: bold;
	font-size: 15px;
}

.ant-tag {
    font-size: 14px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    margin-right: 0px !important;
    padding-bottom: 2px !important;
}