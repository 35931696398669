div {
    /* direction: rtl; */
}
.sv_row{
    display: flex
}

td {
    text-align: right
}

.sv_p_root table td {
    text-align: right

}
.sv_main .sv_container .sv_body .sv_p_root table td, .sv_main .sv_container .sv_body .sv_p_root table th
{
    text-align: right !important;
}

h3{
    text-align: right
}
.sv_header h3{
    text-align: right

}
.sv_main .sv_container .sv_header h3{
    text-align: right
}
table th, table td {
    text-align: right !important;
    width: 16%;
}


.sv_main .sv_container .sv_header h3 {
    font-size: 2em;
    font-weight: 300;
    text-align: right !important;
    margin: 0;
}

body {
    width: unset !important;
}

h4 {
    font-family: Tajawal !important;
    font-size: 15px !important;
}

.sv_main input[type="button"], .sv_main button {
    float: left;
    margin-bottom: 8px;
}

.ant-upload-text {
    font-family: Tajawal;
}

.ant-form-item-label {
    float: right !important;
}