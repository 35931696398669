body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #F8F8F8;
}

.sv_header {
  text-align: 'right'
}

h3 {
  text-align: right
}

.ant-select-selection-selected-value {
	float: right !important;
    max-width: 100%;
	padding-left: 20px !important;
	padding-right: unset !important;
}

.ant-select-arrow {
	left: 11px !important;
	right: unset !important;
}
div {
    /* direction: rtl; */
}
.sv_row{
    display: flex
}

td {
    text-align: right
}

.sv_p_root table td {
    text-align: right

}
.sv_main .sv_container .sv_body .sv_p_root table td, .sv_main .sv_container .sv_body .sv_p_root table th
{
    text-align: right !important;
}

h3{
    text-align: right
}
.sv_header h3{
    text-align: right

}
.sv_main .sv_container .sv_header h3{
    text-align: right
}
table th, table td {
    text-align: right !important;
    width: 16%;
}


.sv_main .sv_container .sv_header h3 {
    font-size: 2em;
    font-weight: 300;
    text-align: right !important;
    margin: 0;
}

body {
    width: unset !important;
}

h4 {
    font-family: Tajawal !important;
    font-size: 15px !important;
}

.sv_main input[type="button"], .sv_main button {
    float: left;
    margin-bottom: 8px;
}

.ant-upload-text {
    font-family: Tajawal;
}

.ant-form-item-label {
    float: right !important;
}
.App {
  /* text-align: center; */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.svd_commercial_text {
  visibility: hidden;
  padding: 0;
}

#surveyCreatorContainer {
  direction: ltr;
  padding-left: 50px;
  padding-right: 50px;
}

.fixModal {
  top: auto !important;
  left: auto !important;
  width: 50% !important;
  height: auto !important;
  overflow: visible !important;
}

.sv_complete_btn {
  visibility: hidden;
}

.sv_header {
  text-align: 'right'
}

.ant-select-selection__placeholder {
  text-align: right !important;
  font-family: Tajawal !important;
  padding-top: 3px;
}

.ant-select-selection-selected-value {
  text-align: right !important;
  font-family: Tajawal !important;
  padding-top: 3px;
}

.ant-input-number-handler-wrap {
  position: absolute;
  left: 0;
}

.ant-tabs-nav {
	font-family: Tajawal;
	font-size: 15px;
	font-weight: bold;
}

.ant-card-body {
	font-family: Tajawal !important;
}

.ant-list-item {
	text-align: right !important;
}

.ant-drawer-body {
	text-align: right !important;
}

.ant-input-suffix {
	/* right: unset !important; */
	/* left: 12px !important; */
}
.logo {
	height: 32px;
	margin: 16px;
	background-image: url(/static/media/joussour.8b1e8549.png)
}

.ant-menu.ant-menu-dark {
	text-align: right !important;
	font-family: Tajawal !important;
	padding-top: 50px !important;
}

.anticon {
	vertical-align: 0.15em !important;
}

.ant-input {
	padding-bottom: 0px !important;
}

.limelight {
	direction: rtl;
	text-align: right;
}

.ant-breadcrumb-link {
	font-family: Tajawal;
	font-weight: bold;
	font-size: 15px;
}

.ant-tag {
    font-size: 14px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    margin-right: 0px !important;
    padding-bottom: 2px !important;
}


.ant-popover-message-title {
    font-family: Tajawal !important;
}

.ant-message {
    font-family: Tajawal !important;
}

.ant-drawer-title {
    font-family: Tajawal !important;
    text-align: center !important;
    font-size: 23px !important;
    padding-top: 5px !important;
    font-weight: bold !important;
}

input {
    text-align: right !important;
    font-family: Tajawal !important;
    padding-top: 5px !important;
}

.has-error {
    text-align: right !important;
}

.sv_main span {
    margin-right: 5px !important;
    font-family: Tajawal;
    font-weight: bold;
    font-size: 17px;
}

.sv_page_title {
    visibility: hidden;
    height: 0px;
}

.ant-divider-horizontal {
    margin: 4px 0 !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup.sv_q_radiogroup_inline:not(:last-child), .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_checkbox.sv_q_checkbox_inline:not(:last-child), .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_imgsel.sv_q_imagepicker_inline:not(:last-child) {
    margin-right: 0 !important;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    font-family: Tajawal !important;
    padding-top: 5px !important;
}

.ant-card-head-title {
    font-family: Tajawal !important;
    padding-top: 15px !important;
}

.ant-descriptions-item-label {
    font-family: Tajawal !important;
    padding-top: 20px !important;
    font-size: 15px !important;
}

.ant-descriptions-item-content {
    font-family: Tajawal !important;
    padding-top: 20px !important;
    font-size: 15px !important;
}

.ant-modal-title {
    font-family: Tajawal !important;
    padding-top: 5px !important;
    font-size: 20px !important;
    text-align: right;
}

.ant-modal-close {
    left: 0 !important;
    right: unset !important;
}

.ant-form-item label {
    font-family: Tajawal !important;
    padding-top: 20px !important;
    font-size: 15px !important;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    text-align: right !important;
}

.ant-modal-footer button + button {
    margin-right: 8px !important;
}
