

.ant-popover-message-title {
    font-family: Tajawal !important;
}

.ant-message {
    font-family: Tajawal !important;
}

.ant-drawer-title {
    font-family: Tajawal !important;
    text-align: center !important;
    font-size: 23px !important;
    padding-top: 5px !important;
    font-weight: bold !important;
}

input {
    text-align: right !important;
    font-family: Tajawal !important;
    padding-top: 5px !important;
}

.has-error {
    text-align: right !important;
}