
.ant-tabs-nav {
	font-family: Tajawal;
	font-size: 15px;
	font-weight: bold;
}

.ant-card-body {
	font-family: Tajawal !important;
}

.ant-list-item {
	text-align: right !important;
}

.ant-drawer-body {
	text-align: right !important;
}

.ant-input-suffix {
	/* right: unset !important; */
	/* left: 12px !important; */
}