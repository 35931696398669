.ant-select-selection-selected-value {
	float: right !important;
    max-width: 100%;
	padding-left: 20px !important;
	padding-right: unset !important;
}

.ant-select-arrow {
	left: 11px !important;
	right: unset !important;
}